import 'keen-slider/keen-slider.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Move, Tag, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

const AUTOPLAY_TIMER = 5000

export interface Props {
  claim?: string
  images?: ImageProps[]
  mobileImage?: ImageProps
  pax?: string
  pricefrom?: string
  size?: string
  subtitle?: string
  variant?: Variant
}

export const Hero = memo(function Hero({
  claim,
  images,
  mobileImage,
  pax,
  pricefrom,
  size,
  subtitle,
  variant = 'default',
}: Props) {
  if (!images) {
    return null
  }

  if (images.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState<boolean[]>([])
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: images.length,
    loop: true,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      images.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <>
      {mobileImage ? (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={mobileImage.src}
            imageSrcSet={mobileImage.srcSet}
          />
        </Helmet>
      ) : null}

      <Container variant={variant}>
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadVertical">
            <Picture decoding="async" {...mobileImage} />
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            <Spinner variant="simple" />
            <Slider ref={sliderRef}>
              {uniq(images).map((item, index) => (
                <Slide
                  key={index}
                  className={currentSlide === index ? 'active' : undefined}
                  style={
                    index !== 0
                      ? {
                          opacity: opacities[index],
                        }
                      : { opacity: 1 }
                  }
                >
                  <Image
                    {...item}
                    alt={loaded[index] ? item.alt : undefined}
                    media="(min-width: 2800px)"
                    src={loaded[index] ? item.src : undefined}
                    srcSet={loaded[index] ? item.srcSet : undefined}
                  />
                </Slide>
              ))}
            </Slider>
            {variant === 'default' ? (
              images.length > 1 ? (
                <Dots>
                  {images.map((item, index) => (
                    <Dot
                      key={index}
                      className={currentSlide === index ? 'active' : undefined}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(index)
                      }}
                    />
                  ))}
                </Dots>
              ) : null
            ) : null}

            {pax || pricefrom || size ? (
              <Info row>
                {pax ? (
                  <Pax dial={4} row>
                    <Users />
                    {pax}
                  </Pax>
                ) : null}
                {size ? (
                  <Size dial={4} row>
                    <Move />
                    {size}
                  </Size>
                ) : null}
                {pricefrom ? (
                  <Pricefrom dial={4} row>
                    <Tag />
                    {pricefrom}
                  </Pricefrom>
                ) : null}
              </Info>
            ) : null}
          </Media>
        </MediaContextProvider>

        {claim && variant !== 'compact' ? (
          <ClaimWrap>
            <Claim>{claim}</Claim>
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </ClaimWrap>
        ) : null}
      </Container>
    </>
  )
})

const Container = styled.section<ContainerProps>`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.primaryDark2};
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 33%;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 3;
  }
  &:before {
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.6)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
    top: 0;
  }
  &:after {
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.6)}
    );
    bottom: 0;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          height: 66.8vh;

          @media (max-width: 767px) {
            height: 50vh;
          }
        `
    }
  }}

  @media (max-width: 767px) {
    &:before {
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      opacity: 0.3;
    }
    &:after {
      display: none;
    }
  }
`

const Picture = styled(Image)`
  width: 100%;
  height: calc(100% - 4.375rem);
  overflow: hidden;
  position: absolute;
  top: 4.375rem;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
  &.active {
    img {
      transform: scale(1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
    transform: scale(1.2);
  }
`

const Dots = styled.div`
  position: absolute;
  top: 50%;
  right: 3rem;
  z-index: 4;
  transform: translateY(-50%);
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  background-color: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin-top: 1rem;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`

const ClaimWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 1.875rem;
  left: 1.875rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1023px) {
    right: 1.875rem;
    left: 1.875rem;
  }

  @media (max-width: 767px) {
    right: 1.25rem;
    left: 1.25rem;
  }
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 400;
  letter-spacing: 0.25rem;
  line-height: 3.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.25rem;
  line-height: 2rem;
  margin-top: 0.5rem;

  @media (max-width: 1023px) {
    font-size: 1.125rem;
  }
`

const Info = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  letter-spacing: 0.1625rem;
  line-height: 1.75rem;
  padding: 1.6875rem 2.3125rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  text-transform: uppercase;
  transform: translateX(-50%);
  z-index: 4;

  > div {
    &:nth-of-type(2) {
      margin-left: 2.25rem;
    }
  }
  svg {
    width: auto;
    height: 1.875rem;
    margin-right: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
  }
`

const Pax = styled(FlexBox)``

const Size = styled(FlexBox)``

const Pricefrom = styled(FlexBox)``

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
